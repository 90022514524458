<template>
  <div class="billingModel">
    <div class="main">
      <h3 class="title">收费标准配置:</h3>
      <!-- <template> -->
      <div class="item" v-for="(item, index) in renderData" :key="index">
        <div class="time">{{ item.name }}:</div>
        <el-input
          v-model="item.value"
          oninput="value=value.replace(/[^0-9.]/g,'')"
          placeholder="请输入内容"
          clearable
        ></el-input>
      </div>
      <!-- </template> -->
    </div>
    <div class="btn">
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      renderData: [
        {
          name: "调度费",
          key: "dispatchPrice",
          value: 0
        },
        {
          name: "遗失赔偿",
          key: "lostPrice",
          value: 0
        },
        {
          name: "超过60分钟后每60分钟价格",
          key: "secondPrice",
          value: 0
        },
        {
          name: "最大价格",
          key: "maxPrice",
          value: 0
        },
        {
          name: "前60分钟价格",
          key: "firstPrice",
          value: 0
        }
      ]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const res = await this.$http.post("/systemConfig/queryFeesRules", {});
      if (res.status === 200) {
        this.renderData.forEach(item => {
          Object.keys(res.data).forEach(key => {
            if (item.key === key) {
              item.value = res.data[key];
            }
          });
        });
      } else {
      }
    },
    async saveConfig() {
      let data = {};
      this.renderData.forEach(item => {
        data[item.key] = item.value;
      });
      const res = await this.$http.post("/systemConfig/updateFeesRules", data);
      if (res.data.code === 200) {
        this.$notify.success({
          title: "提示",
          message: "保存成功",
          showClose: true
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.billingModel {
  padding: 23px 0 80px 100px;
  .title {
    font-size: 23px;
    font-weight: 500;
  }
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .time {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 210px;
      margin-right: 24px;
    }
    /deep/ .el-input {
      width: 300px;
    }
    /deep/ .el-input__inner {
      height: 39px !important;
    }
  }

  .btn {
    width: 300px;
    padding-right: 100px;
    text-align: right;
  }
}
</style>
